import { GetTimelineResponse } from '../infrastructure/api-egg'
import { useQueryClient } from '@tanstack/react-query'
import { setUserPresent } from '../infrastructure/api-present'

export const useActions = () => {
    const queryClient = useQueryClient()
    const actionDispatcher = ({ event, payload }: ActionsTypes) => {
        // if has payload and scope does not include 'app', return
        if (
            payload &&
            'scope' in payload &&
            payload?.scope?.length &&
            !payload.scope?.includes('app')
        )
            return
        try {
            switch (event) {
                case 'ui-team-has-updated': {
                    queryClient.invalidateQueries({ queryKey: ['User', 'Courses'], exact: false })
                    queryClient.invalidateQueries({ queryKey: ['User', 'My Team'] })
                    const courseId = payload?.courseId

                    // Set present when teams has been assigned if required
                    if (courseId && sessionStorage.getItem(`require-present-${courseId}`))
                        setUserPresent(courseId)

                    return
                }

                case 'new-timeline-event': {
                    queryClient.setQueryData(['Team', 'Timeline', payload._courseId], payload)
                    break
                }

                default:
                    console.warn('❗ Action not found:', { type: event, payload })
                    return
            }
        } catch (error) {
            console.error('❗ Error in actionDispatcher:', { type: event, payload, error })
        }
    }

    return { actionDispatcher }
}

type ActionsTypes =
    | {
          event: 'ui-team-has-updated'
          payload?: {
              scope?: string[]
              courseId: string
              name: string
              _algorithmId?: string
          }
      }
    | {
          event: 'new-timeline-event'
          payload: GetTimelineResponse['data']
      }
