import { useCallback, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { GoogleButton } from './GoogleButton'
import { PostCreateUser } from '../pages/register/api-register'
import { decodeSearchParams, isEuropeanSite } from '../helpers/urls'
import { signInWithGoogle } from '../infrastructure/firebase'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { useTranslation } from 'react-i18next'
import { useToken } from '../hooks/useToken'
import { useQueryClient } from '@tanstack/react-query'
import { MeDTO } from '../interfaces/api-activation'
import { Separator } from './Separator'
import { useCreateUser } from '../hooks/queries/useCreateUser'
import { useUIContext } from '../context/UIContext'

export const ContinueWithGoogle = () => {
    const { showToast } = useUIContext()
    const { t } = useTranslation('signIn')
    const [searchParams] = useSearchParams()
    const urlParams = decodeSearchParams<Partial<PostCreateUser>>(searchParams)
    const navigate = useNavigate()
    const { create, migrateAsync, isPending: isCreating } = useCreateUser()
    const { executeRecaptcha } = useGoogleReCaptcha()
    const [isLoading, setIsLoading] = useState(false)
    const { _id } = useToken()
    const queryClient = useQueryClient()

    const handleGoogleSignIn = useCallback(async () => {
        if (!executeRecaptcha) return
        setIsLoading(true)

        const captcha_token = await executeRecaptcha('signInWithGoogle')
        const { token, ...userDataFromFirebase } = await signInWithGoogle(captcha_token)
        if (token) {
            const data = queryClient.getQueryData<MeDTO>(['User', _id])

            const requireMigration = !!data?.user.requireMigration

            if (requireMigration) await migrateAsync(userDataFromFirebase)

            return navigate('/')
        }
    }, [
        _id,
        create,
        executeRecaptcha,
        migrateAsync,
        navigate,
        queryClient,
        urlParams,
        t,
        showToast,
    ])

    if (isEuropeanSite) return null

    return (
        <>
            <GoogleButton
                label={t('Continue with Google')}
                onClick={handleGoogleSignIn}
                disabled={isLoading || isCreating}
            />
            <Separator />
        </>
    )
}
