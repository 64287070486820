import { useEffect } from 'react'
import { useUserCourses } from './queries/useUserCourses'
import { setPresent } from '../infrastructure/api-widget'
import DayJS from '../helpers/DayJS'
import { setUserPresent } from '../infrastructure/api-present'

export const useSetPresent = (courseId?: string) => {
    const { getCourseData } = useUserCourses()
    const course = getCourseData(courseId)
    const key = `present-${courseId}`
    const alreadyPresent = !!sessionStorage.getItem(key)

    useEffect(() => {
        if (!courseId) return
        const { nextClass, status } = course ?? {}
        let presentTimer: NodeJS.Timeout | undefined = undefined

        if (alreadyPresent) return console.info('Already set present for this course.')

        if (!nextClass || status !== 'studying')
            return console.info('Course is not available to set present.')

        // If the class is already open, set present
        // If the class is not open yet, set a timer to open it
        if (nextClass.isInRange) handlePresent(courseId)
        else {
            const openTime = DayJS(nextClass.open)
            const fromNow = openTime.diff(DayJS(), 'milliseconds')
            if (fromNow < 0) return
            console.info(
                `A timer was set for the user to set present at ${openTime
                    .locale('en')
                    .format()}, ${openTime.locale('en').fromNow()}`
            )
            presentTimer = setTimeout(() => handlePresent(courseId), fromNow)
        }

        return () => {
            console.info(`A timer for course ${courseId} was cleared.`)
            clearTimeout(presentTimer)
        }
    }, [alreadyPresent, course, course?.nextClass, course?.status, courseId])

    return { alreadyPresent }
}

export const handlePresent = (courseId: string) => {
    // Canary locations
    const canaryLocation = [
        'http://localhost:8000',
        'https://develop.app.egg.live',
        'https://staging.app.egg.live',
        'https://demo.app.egg.live',
    ]

    // Canary courses
    const canaryCourseIds = [
        '6537e1fe611e3ffae5dbc54d',
        '667d7f321224c88f1f853470',
        '6537e1fe611e3ffae5dbc54d',
        '646e2784ab8b1459d25dbdf0',
    ]

    const useNewPresent =
        canaryLocation.includes(window?.location.origin) || canaryCourseIds.includes(courseId)

    return useNewPresent ? setUserPresent(courseId) : setPresent(courseId)
}
