import styled from 'styled-components'
import ReactModal from 'react-modal'
import { modalCards } from '../../styles/themes/defaultTheme'
import { useUpdateUser } from '../../hooks/queries/useUpdateUser'
import { useTranslation } from 'react-i18next'
import { useUser } from '../../hooks/queries/useUser'
import { useEffect } from 'react'

export const TermsAndConditions = ({ show = false }: { show: boolean }) => {
    const { t } = useTranslation('home')

    const { isUpdating, updateAsync } = useUpdateUser()

    const processTermsAgreement = async (termsAndConditions: boolean) => {
        await updateAsync({ termsAndConditions })
        window.location.href = termsAndConditions ? '/' : '/logout'
    }

    return (
        <ReactModal
            style={modalCards}
            isOpen={show}
            shouldCloseOnEsc={false}
            shouldCloseOnOverlayClick={false}
        >
            <Styles className="redesign">
                <section>
                    <h1>{t('Terms and Conditions')}</h1>
                    <p>
                        {t(
                            'Before proceeding, you must read and accept our Terms and Conditions. This document contain important information about using the platform.'
                        )}
                    </p>
                    <p>
                        {t('You can review them at the following link:')}
                        <br />
                        <a
                            href="https://egg.live/terms-and-conditions"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {t('Terms and Conditions')}
                        </a>
                    </p>
                </section>
                <footer>
                    <button
                        className="outlined"
                        onClick={() => processTermsAgreement(false)}
                        disabled={isUpdating}
                    >
                        {t('Logout')}
                    </button>
                    <button
                        className="primary"
                        onClick={() => processTermsAgreement(true)}
                        disabled={isUpdating}
                    >
                        {t('Accept')}
                    </button>
                </footer>
            </Styles>
        </ReactModal>
    )
}

const Styles = styled.main`
    display: grid;
    place-items: center;
    gap: 4em;
    padding: 3em;
    background-color: white;
    border-radius: 16px;
    max-width: 50ch;

    & > footer {
        width: 100%;
        display: flex;
        gap: 1em;
        justify-content: flex-end;
    }

    @media screen and (max-width: 768px) {
        gap: 2em;
        padding: 2em 1em;
        .logout-modal-title {
            font-size: 1.5em;
        }
    }
`
