import { useTranslation } from 'react-i18next'
import IntercomSDK from '@intercom/messenger-js-sdk'
import { isEuropeanSite } from '../helpers/urls'

const { REACT_APP_INTERCOM_APP_ID: app_id } = process.env

// Load Intercom if it's available
if (!app_id)
    console.warn(
        'Intercom is not available in this site, please set app_id in environment variables'
    )
else IntercomSDK({ app_id, region: isEuropeanSite ? 'eu' : 'us' })

export const useIntercom = () => {
    const { t } = useTranslation('intercom')
    const Client = window.Intercom

    const newMessage = (message?: string) => Client?.('showNewMessage', message)

    const chatWithSales = (message?: string) =>
        Client?.(
            'showNewMessage',
            message ?? t('Hello!\n\nI want to chat with a consultant if possible please.')
        )

    const chatWithSupport = (message?: string) =>
        Client?.('showNewMessage', message ?? t('Hello!\n\nCan you help me please.'))

    const clientUpdate = (settings: Intercom_.IntercomSettings) => Client?.('update', settings)

    const clientBoot = (settings: Intercom_.IntercomSettings) => Client?.('boot', settings)

    const openChat = () => Client?.('show')

    const trackEvent = (...params: TrackEventParams) => Client?.('trackEvent', ...params)

    return {
        Client,
        openChat,
        chatWithSales,
        chatWithSupport,
        clientUpdate,
        trackEvent,
        clientBoot,
        newMessage,
    }
}

type TrackEventParams = Parameters<Intercom_.IntercomCommandSignature['trackEvent']>
